import { Box, Container, Grid, Typography } from "@mui/material";

function Footer() {
  return (
    <div>
      <Typography align="center" marginTop={4}>
        <h3>
          Nếu ST không check ra mã bill thì liên hệ Trade - Logistics theo từng
          miền như sau
        </h3>
      </Typography>

      <Box
        px={{ xs: 2, sm: 7 }}
        py={{ xs: 2, sm: 7 }}
        bgcolor="#caced1"
        marginTop={4}
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item className="footer-left">
              <Typography variant="h5" marginBottom={2} fontWeight={600}>
                Chuỗi TGD/ĐMX/ĐMS/TopZone/An Khang/AVA
              </Typography>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                1. Đông Tây Bắc: Phạm Anh Dũng 21654
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                2. Đồng Bằng Sông Hồng: Kim Văn Bách 45030
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                3. Hà Nội+: Trần Đức Anh 26475
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                4. Trung Bộ: Ngô Thành Đạt 43063, Đặng Dương Thiện 196005
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                5. Duyên Hải: Ngô Thành Đạt 43063, Đặng Dương Thiện 196005
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                6. Đông Cao Nguyên: Phan Trần Dũng Long 177339, Đặng Hoàng Tuyến 18523
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                7. Hồ Chí Minh: Võ Thành Lộc 167858, Hứa Quý Cảnh 60002, Phạm Tuấn Nguyên 143027
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                8. Tây Nam Bộ: Nguyễn Thành Đạt 26452, Nguyễn Thị Mỹ Linh 36458
              </Box>
            </Grid>
            <Grid item className="footer-right">
              <Typography variant="h5" marginBottom={2} fontWeight={600}>
                Chuỗi cửa hàng Bách Hóa Xanh
              </Typography>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                1. Hồ Chí Minh: Trần Đức Lợi 31357
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                2. Tây Nam Bộ: Nguyễn Thị Mỹ Linh 36458
              </Box>
              <Box borderBottom={1} paddingBottom={1} marginBottom={2}>
                3. Miền Đông, Tây Nguyên, Nam Trung Bộ: Võ Thành Lộc 167858
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        textAlign="center"
        pt={{ xs: 2, sm: 2 }}
        pb={{ xs: 2, sm: 2 }}
        bgcolor="#a2a5a7"
        color="#fff"
      >
        © 2022 Copyright - All rights reserved
      </Box>
    </div>
  );
}

export default Footer;
